import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoGuestGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userObservable.pipe(
      take(1),
      map( user => {
        console.log('no-guest-guard!', user);
        if (user.email !== 'iso.intranet@iso-team.com') {
          return !!user;
        } else {
          this.router.navigate(['/', 'general', 'index']);
        }
      })
      // map(user => !!user),
      // tap(loggedIn => {
      //   if (!loggedIn) {
      //     this.router.navigate(['/', 'auth', 'login']);
      //   }
      // })
    );
  }

}
