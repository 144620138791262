import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable} from 'rxjs';
import { UserInterface } from './user.interface';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { of } from 'rxjs/internal/observable/of';
import {map, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userObservable: Observable<UserInterface>;  // not private because it is used in AuthGuard
  private authSystemMessage = new BehaviorSubject('');
  public currentAuthSystemMessage = this.authSystemMessage.asObservable();

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private router: Router
  ) {
    this.userObservable = this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.angularFirestore.doc<UserInterface>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  public changeAuthSystemMessage(message: string) {
    this.authSystemMessage.next(message);
  }

  public signInWithEmailAndPasswordAuthService(email, password) {
    this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        const updateUserResults = this.updateUserData(credential.user);
        console.log('auth.service.ts - UPDATE USER RESULTS!');
        console.log(updateUserResults);
      })
      .catch(error => {
        this.authSystemMessage.next(error.message);
      });
  }

  public signUpWithEmailAndPasswordAuthService(status: boolean, credential: any) {
    if (status) {
      this.updateUserData(credential.user);
    }
  }

  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`users/${user.uid}`);
    userRef.snapshotChanges().pipe(
      map(a => {
        if (a.payload.exists) {
          const returnedData = a.payload.data();
          const uid = a.payload.id;
          return {uid, ...returnedData};
        } else {
          return null;
        }
      })
    ).subscribe( results => {
      if (results !== null) {
        const data: UserInterface = {
          uid: results.uid,
          email: results.email,
          role: results.role,
          displayName: results.displayName,
          photoURL: results.photoURL,
          latestProfile: results.lineItem,
          isActive: results.isActive,
        };
        this.router.navigateByUrl('');
        userRef.set(data, { merge: true });
        return { status: 'success', message: 'Logged In' };
      } else {
        console.log('We drop this because we dont want to create vendor here');
        // (START) Deprecated on 7th Oct 2020
        // const data: UserInterface = {
        //   uid: user.uid,
        //   email: user.email,
        //   role: environment.CONSTANTS.ALL_AUTHENTICATION_ROLES.public,
        //   displayName: user.displayName,
        //   photoURL: user.photoURL,
        //   isActive: true,
        // };
        // this.router.navigateByUrl('');
        // userRef.set(data, { merge: true });
        // (END) Deprecated on 7th Oct 2020
        this.angularFireAuth.auth.signOut()
          .then((signOutres) => {
            this.router.navigateByUrl('auth/login').then(loggedOutResults => {
              // console.log('loggedOutResults');
            });
          });
        return { status: 'failure', message: 'Logged out' };
      }
    });
  }

  public LogoutAuthService() {
    this.angularFireAuth.auth.signOut()
      .then((res) => {
        this.router.navigateByUrl('auth/login').then(results => {
          console.log('results');
        });
      });
  }

  // [U] Update Password
  public updatePasswordService(updatePasswordForm: any) {
    console.log('updatePasswordService');
    return this.userObservable.pipe(
      take(1),
      map(params => {
        console.log('in map!');
        return params;
      }),
      switchMap(params => {
        console.log('in switch map!');
        console.log(params);
        if (params.email !== null && params.email !== undefined) {
          // Logic for assigning UID
          // const angularFireStoreGeneratedID = this.angularFirestore.createId();
          // this.accountImportExcelFileToBeImported = {
          //   uid: angularFireStoreGeneratedID,
          //   addedBy: params,
          //   dateAdded: new Date(),
          //   filename: form.value.filename,
          //   truncatedStoragePath: filePath,
          // };
          // this.accountImportFilesCollection.doc(angularFireStoreGeneratedID).set(this.accountImportExcelFileToBeImported);
          console.log('Update User Password here');
          console.log(updatePasswordForm);
          console.log(updatePasswordForm.value.password);
          console.log(updatePasswordForm.value.passwordConfirm);
          return from(this.angularFireAuth.auth.currentUser.updatePassword(updatePasswordForm.value.password));
          // return of({status: 'success', message: 'Uploaded File in Library'});
        } else {
          return of({status: 'error', message: 'Did not create file in library'});
        }
      })
    );
  }

}
