import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import {NoGuestGuard} from './auth/no-guest.guard';
import {RfqGuard} from "./rfq/rfq.guard";


const appRoutes: Routes = [
  { path: '',
    loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
    canActivate: [AuthGuard]
  },
  { path: 'library',
    loadChildren: () => import('./library/library.module').then(m => m.LibraryModule),
    canActivate: [NoGuestGuard]
  },
  { path: 'geolocation',
    loadChildren: () => import('./geolocation/geolocation.module').then(m => m.GeolocationModule),
    canActivate: [NoGuestGuard]
  },
  { path: 'project',
    loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    canActivate: [AuthGuard, NoGuestGuard]
  },
  { path: 'education',
    loadChildren: () => import('./education/education.module').then(m => m.EducationModule),
    canActivate: [NoGuestGuard]
  },
  { path: 'rfq',
    loadChildren: () => import('./rfq/rfq.module').then(m => m.RfqModule),
    canActivate: [NoGuestGuard, RfqGuard]
  },
  { path: 'livestream',
    loadChildren: () => import('./livestream/livestream.module').then(m => m.LivestreamModule),
    canActivate: [NoGuestGuard]
  },
  { path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { path: 'vms',
    loadChildren: () => import('./vms/vms.module').then(m => m.VmsModule),
    canActivate: [NoGuestGuard]
  },
  { path: 'ncov',
    loadChildren: () => import('./special/ncov/office-monitor/office-monitor.module').then(m => m.OfficeMonitorModule),
    canActivate: [NoGuestGuard]
  },
  // { path: '.well-known',
  //   loadChildren: () => import('./config/config.module').then(m => m.ConfigModule)
  // },
  {path: '**', redirectTo: 'auth/login'},
  // { path: 'account',
  //   loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  //   canActivate: [AuthGuard]
  // }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
