import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import {NgxLinkifyjsModule} from 'ngx-linkifyjs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireFunctionsModule, FUNCTIONS_ORIGIN} from '@angular/fire/functions';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule, // Authentication Module
    AngularFirestoreModule, // Database: Cloud Firestore (More Robust than Realtime Database)
    AngularFireStorageModule, // File Storage
    AngularFireFunctionsModule, // AngularFire connect to Firebase Functions directly
    FontAwesomeModule, // Font Awesome
    HttpClientModule,
    NgxLinkifyjsModule.forRoot(),
    BrowserAnimationsModule // Required for NgxCharts
    // NgxChartsModule
    // HotTableModule.forRoot(),
    // NgbModule,
    // NgbCarouselModule
  ],
  providers: [
    { provide: FUNCTIONS_ORIGIN, useValue: 'https://production-isoteam-app.web.app' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
