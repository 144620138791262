import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";
import {ProjectService} from "../project/project.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RfqGuard implements CanActivate {
  public rfqAllowableStaffEmailList = [
    'david@gracet.sg',
    'alex.tai@iso-team.com',
    'diana.low@iso-team.com',
    'sera.teo@iso-team.com',
    'michelle.siak@iso-team.com',
    'jasmine.yap@iso-team.com'
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
    return this.authService.userObservable
      .pipe(
        map( user => {
          if (user.role === 'iso_super_admin') {
            return !!user;
          } else if ( this.rfqAllowableStaffEmailList.includes(user.email) ) {
            return !!user;
          } else {
            this.router.navigate(['/', 'general', 'index']);
          }
        })
      );
  }

}
