// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDKtmgn19ms8hARl2WYCZTbFf6M-ixHNlI',
    authDomain: 'production-isoteam-app.firebaseapp.com',
    databaseURL: 'https://production-isoteam-app.firebaseio.com',
    projectId: 'production-isoteam-app',
    storageBucket: 'production-isoteam-app.appspot.com',
    messagingSenderId: '73271237715',
    appId: '1:73271237715:web:c6d682910e5267ab'
  },
  CONSTANTS: {
    ALL_AUTHENTICATION_ROLES: {
      public: 'public',
      iso_site_worker: 'iso_worker',
      iso_office_staff: 'iso_office_staff',
      iso_super_admin: 'iso_super_admin',
      external_vendor: 'external_vendor', // Vendors include Suppliers, Subcontractors etc.
      external_client: 'external_client', // Vendors include Suppliers, Subcontractors etc.
      others: 'others',
    },
    AVAILABLE_CATEGORY: [
      { value: 'organisation_chart', displayName: 'Organisation Chart' },
      { value: 'staff_handbook', displayName: 'Staff Handbook' },
      { value: 'training_manual', displayName: 'Training Manual' },
    ],
    AVAILABILITY_OPTIONS: [
      { value: 'viewable', displayName: 'Viewable' },
      { value: 'downloadable', displayName: 'Downloadable' },
    ],
    GABP_CREATE_CONSTANTS: {
      GOOD_OR_BAD_PRACTICE_OPTIONS: [
        { value: 'good_practice', displayName: 'Good Practice' },
        { value: 'bad_practice', displayName: 'Malpractice' },
      ],
    },
    CALENDAR_TYPE_OF_EVENTS : [
      { text: 'Internal Event', value: 'internal_event'},
      { text: 'Public Holiday', value: 'public_holiday'},
    ],
    CALENDAR_MONTH: [
      { value: 1, displayName: 'January' },
      { value: 2, displayName: 'February' },
      { value: 3, displayName: 'March' },
      { value: 4, displayName: 'April' },
      { value: 5, displayName: 'May' },
      { value: 6, displayName: 'June' },
      { value: 7, displayName: 'July' },
      { value: 8, displayName: 'August' },
      { value: 9, displayName: 'September' },
      { value: 10, displayName: 'October' },
      { value: 11, displayName: 'November' },
      { value: 12, displayName: 'December' },
    ],
    CALENDAR_YEAR: [
      { value: 2015, displayName: '2015' },
      { value: 2016, displayName: '2016' },
      { value: 2017, displayName: '2017' },
      { value: 2018, displayName: '2018' },
      { value: 2019, displayName: '2019' },
      { value: 2020, displayName: '2020' },
      { value: 2021, displayName: '2021' },
      { value: 2022, displayName: '2022' },
      { value: 2023, displayName: '2023' },
    ],
    NOTICE_FILE_TYPE_OPTIONS: [
      { value: 'pdf', displayName: 'pdf' },
      { value: 'image', displayName: 'image' },
      { value: 'audio', displayName: 'audio' },
      { value: 'video', displayName: 'video' },
    ],
    TIME_SLOTS_FOR_BOOKING : [
      { integer: 0, time: '00:00', bookings: [] },
      { integer: 1, time: '00:30', bookings: [] },
      { integer: 2, time: '01:00', bookings: [] },
      { integer: 3, time: '01:30', bookings: [] },
      { integer: 4, time: '02:00', bookings: [] },
      { integer: 5, time: '02:30', bookings: [] },
      { integer: 6, time: '03:00', bookings: [] },
      { integer: 7, time: '03:30', bookings: [] },
      { integer: 8, time: '04:00', bookings: [] },
      { integer: 9, time: '04:30', bookings: [] },
      { integer: 10, time: '05:00', bookings: [] },
      { integer: 11, time: '05:30', bookings: [] },
      { integer: 12, time: '06:00', bookings: [] },
      { integer: 13, time: '06:30', bookings: [] },
      { integer: 14, time: '07:00', bookings: [] },
      { integer: 15, time: '07:30', bookings: [] },
      { integer: 16, time: '08:00', bookings: [] },
      { integer: 17, time: '08:30', bookings: [] },
      { integer: 18, time: '09:00', bookings: [] },
      { integer: 19, time: '09:30', bookings: [] },
      { integer: 20, time: '10:00', bookings: [] },
      { integer: 21, time: '10:30', bookings: [] },
      { integer: 22, time: '11:00', bookings: [] },
      { integer: 23, time: '11:30', bookings: [] },
      { integer: 24, time: '12:00', bookings: [] },
      { integer: 25, time: '12:30', bookings: [] },
      { integer: 26, time: '13:00', bookings: [] },
      { integer: 27, time: '13:30', bookings: [] },
      { integer: 28, time: '14:00', bookings: [] },
      { integer: 29, time: '14:30', bookings: [] },
      { integer: 30, time: '15:00', bookings: [] },
      { integer: 31, time: '15:30', bookings: [] },
      { integer: 32, time: '16:00', bookings: [] },
      { integer: 33, time: '16:30', bookings: [] },
      { integer: 34, time: '17:00', bookings: [] },
      { integer: 35, time: '17:30', bookings: [] },
      { integer: 36, time: '18:00', bookings: [] },
      { integer: 37, time: '18:30', bookings: [] },
      { integer: 38, time: '19:00', bookings: [] },
      { integer: 39, time: '19:30', bookings: [] },
      { integer: 40, time: '20:00', bookings: [] },
      { integer: 41, time: '20:30', bookings: [] },
      { integer: 42, time: '21:00', bookings: [] },
      { integer: 43, time: '21:30', bookings: [] },
      { integer: 44, time: '22:00', bookings: [] },
      { integer: 45, time: '22:30', bookings: [] },
      { integer: 46, time: '23:00', bookings: [] },
      { integer: 47, time: '23:30', bookings: [] }
    ],
    TIME_RANGE_SLOTS_FOR_BOOKING : [
      { bookFromInteger: 0, bookToInteger: 1, timeDisplay: '00:00 - 00:30', bookings: [] },
      { bookFromInteger: 1, bookToInteger: 2, timeDisplay: '00:30 - 01:00', bookings: [] },
      { bookFromInteger: 2, bookToInteger: 3, timeDisplay: '01:00 - 01:30', bookings: [] },
      { bookFromInteger: 3, bookToInteger: 4, timeDisplay: '01:30 - 02:00', bookings: [] },
      { bookFromInteger: 4, bookToInteger: 5, timeDisplay: '02:00 - 02:30', bookings: [] },
      { bookFromInteger: 5, bookToInteger: 6, timeDisplay: '02:30 - 03:00', bookings: [] },
      { bookFromInteger: 6, bookToInteger: 7, timeDisplay: '03:00 - 03:30', bookings: [] },
      { bookFromInteger: 7, bookToInteger: 8, timeDisplay: '03:30 - 04:00', bookings: [] },
      { bookFromInteger: 8, bookToInteger: 9, timeDisplay: '04:00 - 04:30', bookings: [] },
      { bookFromInteger: 9, bookToInteger: 10, timeDisplay: '04:30 - 05:00', bookings: [] },
      { bookFromInteger: 10, bookToInteger: 11, timeDisplay: '05:00 - 05:30', bookings: [] },
      { bookFromInteger: 11, bookToInteger: 12, timeDisplay: '05:30 - 06:00', bookings: [] },
      { bookFromInteger: 12, bookToInteger: 13, timeDisplay: '06:00 - 06:30', bookings: [] },
      { bookFromInteger: 13, bookToInteger: 14, timeDisplay: '06:30 - 07:00', bookings: [] },
      { bookFromInteger: 14, bookToInteger: 15, timeDisplay: '07:00 - 07:30', bookings: [] },
      { bookFromInteger: 15, bookToInteger: 16, timeDisplay: '07:30 - 08:00', bookings: [] },
      { bookFromInteger: 16, bookToInteger: 17, timeDisplay: '08:00 - 08:30', bookings: [] },
      { bookFromInteger: 17, bookToInteger: 18, timeDisplay: '08:30 - 09:00', bookings: [] },
      { bookFromInteger: 18, bookToInteger: 19, timeDisplay: '09:00 - 09:30', bookings: [] },
      { bookFromInteger: 19, bookToInteger: 20, timeDisplay: '09:30 - 10:00', bookings: [] },
      { bookFromInteger: 20, bookToInteger: 21, timeDisplay: '10:00 - 10:30', bookings: [] },
      { bookFromInteger: 21, bookToInteger: 22, timeDisplay: '10:30 - 11:00', bookings: [] },
      { bookFromInteger: 22, bookToInteger: 23, timeDisplay: '11:00 - 11:30', bookings: [] },
      { bookFromInteger: 23, bookToInteger: 24, timeDisplay: '11:30 - 12:00', bookings: [] },
      { bookFromInteger: 24, bookToInteger: 25, timeDisplay: '12:00 - 12:30', bookings: [] },
      { bookFromInteger: 25, bookToInteger: 26, timeDisplay: '12:30 - 13:00', bookings: [] },
      { bookFromInteger: 26, bookToInteger: 27, timeDisplay: '13:00 - 13:30', bookings: [] },
      { bookFromInteger: 27, bookToInteger: 28, timeDisplay: '13:30 - 14:00', bookings: [] },
      { bookFromInteger: 28, bookToInteger: 29, timeDisplay: '14:00 - 14:30', bookings: [] },
      { bookFromInteger: 29, bookToInteger: 30, timeDisplay: '14:30 - 15:00', bookings: [] },
      { bookFromInteger: 30, bookToInteger: 31, timeDisplay: '15:00 - 15:30', bookings: [] },
      { bookFromInteger: 31, bookToInteger: 32, timeDisplay: '15:30 - 16:00', bookings: [] },
      { bookFromInteger: 32, bookToInteger: 33, timeDisplay: '16:00 - 16:30', bookings: [] },
      { bookFromInteger: 33, bookToInteger: 34, timeDisplay: '16:30 - 17:00', bookings: [] },
      { bookFromInteger: 34, bookToInteger: 35, timeDisplay: '17:00 - 17:30', bookings: [] },
      { bookFromInteger: 35, bookToInteger: 36, timeDisplay: '17:30 - 18:00', bookings: [] },
      { bookFromInteger: 36, bookToInteger: 37, timeDisplay: '18:00 - 18:30', bookings: [] },
      { bookFromInteger: 37, bookToInteger: 38, timeDisplay: '18:30 - 19:00', bookings: [] },
      { bookFromInteger: 38, bookToInteger: 39, timeDisplay: '19:00 - 19:30', bookings: [] },
      { bookFromInteger: 39, bookToInteger: 40, timeDisplay: '19:30 - 20:00', bookings: [] },
      { bookFromInteger: 40, bookToInteger: 41, timeDisplay: '20:00 - 20:30', bookings: [] },
      { bookFromInteger: 41, bookToInteger: 42, timeDisplay: '20:30 - 21:00', bookings: [] },
      { bookFromInteger: 42, bookToInteger: 43, timeDisplay: '21:00 - 21:30', bookings: [] },
      { bookFromInteger: 43, bookToInteger: 44, timeDisplay: '21:30 - 22:00', bookings: [] },
      { bookFromInteger: 44, bookToInteger: 45, timeDisplay: '22:00 - 22:30', bookings: [] },
      { bookFromInteger: 45, bookToInteger: 46, timeDisplay: '22:30 - 23:00', bookings: [] },
      { bookFromInteger: 46, bookToInteger: 47, timeDisplay: '23:00 - 23:30', bookings: [] },
      { bookFromInteger: 47, bookToInteger: 48, timeDisplay: '23:30 - 24:00', bookings: [] }
    ],
    // PROJECT CONSTANTS
    PROJECT_FILES_CREATE_CONSTANTS: {
      FILE_TYPE_OPTIONS: [
        { value: 'admin', displayName: 'Admin' },
        { value: 'authorities-approval', displayName: 'Authorities Approval' },
        { value: 'complaints', displayName: 'Complaints & Fines' },
        { value: 'inspection', displayName: 'Inspection Records' },
        { value: 'material-approval', displayName: 'Materials Approval' },
        { value: 'material-test-reports', displayName: 'Materials Test Reports by Approval Laboratory' },
        { value: 'work-program', displayName: 'Work Program' },
        { value: 'organization-chart', displayName: 'Organization Chart' },
        { value: 'others', displayName: 'Others' },
        { value: 'outstanding-vendor-list', displayName: 'Outstanding vendor list (Note: Others are sensitive info)' },
        { value: 'pe-certification', displayName: `PE'S Certification of Supervision` },
        { value: 'pre-con', displayName: 'Pre-Con Photo' },
        { value: 'specification',
          displayName: 'Project Specification & Drawing (Note: Original spec, drawing, amendment if any, no sensitive pricing)' },
        { value: 'signed-hoto', displayName: 'Signed handing over form / as-built drawing' },
        { value: 'undertaking-roof-pump', displayName: 'Undertaking to Roof/Pump Room (Evidence of returned of roof keys to client)' },
        { value: 'certification', displayName: 'Project Certification' },
        { value: 'transmittal-form', displayName: 'Transmittal Form' },
        { value: 'meeting-minutes', displayName: 'Meeting Minutes' },
        { value: 'ppr-report', displayName: 'Post Project Review Report' },
        { value: 'completion', displayName: 'Completion Photo' },
        // { value: 'other', displayName: 'Others' },
      ],
      FILE_TYPE_OPTIONS_ARRAY: [
        'admin',
        'authorities-approval',
        'complaints',
        'inspection',
        'material-approval',
        'material-test-reports',
        'work-program',
        'organization-chart',
        'others',
        'outstanding-vendor-list',
        'pe-certification',
        'pre-con',
        'specification',
        'signed-hoto',
        'undertaking-roof-pump',
        'certification',
        'transmittal-form',
        'meeting-minutes',
        'ppr-report',
        'completion'
      ]
    },
    PROJECT: {
      PROJECT_RFI: {
        PROJECT_RFI_CREATE_CONSTANTS: {
          RFI_STATUS_OPTIONS: [
            { value: 'open', displayName: 'Open Request' },
            { value: 'completed', displayName: 'Completed Request' },
            { value: 'attention', displayName: 'Request that requires attention' },
          ],
        },
      },
      PROJECT_STATUS_OPTIONS: [
        { value: 'active', displayName: 'Project is active' },
        { value: 'completed', displayName: 'Project has completed' },
        { value: 'paused', displayName: 'Project is paused currently' },
      ],
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
